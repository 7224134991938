import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

const Fortress = window.cccisd.fortress;
const Appdefs = window.cccisd.appDefs;

const questTabPermissions = [
    'quest.admin',
    'quest.design',
    'quest.deploy',
    'quest.status',
    'quest.code',
    'quest.tables',
    'quest.permissions',
];

const NavBar = ({ className, noLogoLink }) => {
    let primaryNavLinks = [];

    try {
        primaryNavLinks = Appdefs.navs.find(n => n.handle === 'primaryNav').nav;
    } catch (e) {
        // meh, leave it empty
    }

    if (
        Fortress &&
        Fortress.auth() &&
        Fortress.hasAnyAccess(questTabPermissions, 'anyTarget') &&
        !primaryNavLinks.find(n => n.label === 'User Guide')
    ) {
        primaryNavLinks.push({
            url: 'https://support.quest4data.com/support',
            label: 'User Guide',
        });
    }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logo}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            {...(noLogoLink ? { logoLink: null } : {})}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
    noLogoLink: false,
};

NavBar.propTypes = {
    className: PropTypes.string,
    noLogoLink: PropTypes.bool,
};

export default NavBar;
